import React, { useState } from 'react';
import emailjs from "emailjs-com"
import Login from "./Login/Login"
import "./Navbar.css"
import logo from "./Images/AST_Logo Clear.png"


function Navbar() {
        const [display, setdisplay] = useState("contact-modal");

        function displaycount() {
            setdisplay(display === "contact-modal-display"
          ? "contact-modal"
          : "contact-modal-display",
            )};

        function sendEmail(e) {
            e.preventDefault();

            emailjs.sendForm('service_xsx2qql', 'template_agtbe0j', e.target, 'user_8ZYC5YaVvrODYrotDvyUU')
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
              displaycount()
        }

        return ( 
            <div className="container-navbar">
                <div className="navbar">
                    <div className="left-side">
                        <input type="checkbox" id="check" />
                        <label htmlFor="check">
                        <i className="fas fa-bars" id="btn" />
                        <i className="fas fa-times" id="cancel" />
                        </label>
                        <div className="sidebar">
                        <header>Navigation Bar</header>
                            <ul>
                            <li><a href="/home"><i className="fas fa-house" />Home</a></li>
                            <li><a href="/live"><i className="fa-bars-progress" />Live Viewer</a></li>
                            <li><a href="/pumpData"><i className="fa-bars-progress" />Pump Data</a></li>
                            <li><a href="/pumpMaintenance"><i className="fa-bars-progress" />Pump Maintenance</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="middle-side">
                        <p>{}</p>
                    </div>
                    <div className="right-side">
                        <div className="loginbut">
                            <Login/>
                        </div>
                        <div className="nav-contact">
                            <button onClick={displaycount} className="btn btn-outline-primary">Contact Us</button>
                        </div>
                    </div>
                </div>
                <div className={display}>
                    <div className="contact-modal-content">
                    <div onClick={displaycount} className="contact-close">+</div>
                    <img src={logo} id="ast-logo" alt="AST Logo" />
                    <form onSubmit={sendEmail}>
                        <input type="text" placeholder="Name" name="name"/>
                        <input type="text" placeholder="E-mail" name="email"/>
                        <input type="text" placeholder="Phone Number" name="phoneNumber"/>
                        <textarea placeholder="Concern/Issue" rows="5" name="message"/>
                        <div className="submit-button">
                        <button className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
         );
}
 
export default Navbar;