import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ReactLoading from 'react-loading';
import jwt_decode from 'jwt-decode';

import FileManipulation from './FileManipulation';
import Login from './Login/LoginPage';
import LiveViewer from './Live';
import PumpDataChart from './pumpData';
import PumpMaintenance from './pumpMaintenance';
const App = () => {
  const { getAccessTokenSilently, isLoading: authLoading, isAuthenticated } = useAuth0();
  const [readPermission, setReadPermission] = useState(false);
  const [writePermission, setWritePermission] = useState(false);
  const [deleteFilePermission, setDeleteFilePermission] = useState(false);
  const [deleteFolderPermission, setDeleteFolderPermission] = useState(false);
  const [permissions, setPermissions] = useState(false)

  const fetchManagementApiAccessToken = useCallback(async () => {
    if(permissions === false){
      setPermissions(true);
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://astsand.com',
        },
      });
      localStorage.setItem('AccessToken', accessToken);
      const decoded = jwt_decode(accessToken);;
      for (const p of decoded.permissions) {
        if (p === 'read:tickets') {
          setReadPermission(true);
        }
        if (p === 'write:tickets') {
          setWritePermission(true);
        }
        if (p === 'delete:tickets') {
          setDeleteFilePermission(true);
        }
        if (p === 'delete:folders') {
          setDeleteFolderPermission(true);
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  }}, [getAccessTokenSilently]);

  useEffect(() => {
    fetchManagementApiAccessToken();
  }, [fetchManagementApiAccessToken]);

  if (authLoading) {
    return (
      <div className="loader-spin">
        <ReactLoading type={'spin'} color={'cyan'} />
      </div>
    );
  }

  const PrivateRoute1 = withAuthenticationRequired(FileManipulation, {
    onRedirecting: () => <div>Loading...</div>,
  });

  const PrivateRoute2 = withAuthenticationRequired(LiveViewer, {
    onRedirecting: () => <div>Loading...</div>,
  });

  const PrivateRoute3 = withAuthenticationRequired(PumpMaintenance, {
    onRedirecting: () => <div>Loading...</div>,
  });
  return (
  <Router>
    <Routes>
      {isAuthenticated ? (
        <Route
          path="/"
          element={
            <PrivateRoute1
              readPermission={readPermission}
              writePermission={writePermission}
              deleteFilePermission={deleteFilePermission}
              deleteFolderPermission={deleteFolderPermission}
            />
          }
        />
      ) : (
        <Route path="/" element={<Login />} />
      )}
      <Route
        path="/home"
        element={
          <PrivateRoute1
            readPermission={readPermission}
            writePermission={writePermission}
            deleteFilePermission={deleteFilePermission}
            deleteFolderPermission={deleteFolderPermission}
          />
        }
      />
      <Route
        path="/live"
        element={
          <PrivateRoute2
            readPermission={readPermission}
            writePermission={writePermission}
            deleteFilePermission={deleteFilePermission}
            deleteFolderPermission={deleteFolderPermission}
          />
        }
      />
      <Route path="/pumpData" element={<PumpDataChart />}/>
      <Route
        path="/pumpMaintenance"
        element={
          <PrivateRoute3
            readPermission={readPermission}
            writePermission={writePermission}
            deleteFilePermission={deleteFilePermission}
            deleteFolderPermission={deleteFolderPermission}
          />
        }
      />
    </Routes>
  </Router>
);
};

export default App;




