import React, { Fragment, useEffect, useState } from 'react';
import { Amplify, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import Navbar from './Navbar';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';

const LiveViewer = () => {
  const [fleetData, setFleetData] = useState({ fleet1: [], fleet2: [], fleet3: [], fleet4: [] });

  useEffect(() => {
    const parseData = (parsedData) => {
      return Array.from({ length: 24 }, (_, i) => ({
        inlineNumber: i + 1,
        unitNumber: parsedData[`value${i * 22 + 1}`],
        engineHours: parsedData[`value${i * 22 + 2}`],
        transmissionHours: parsedData[`value${i * 22 + 3}`],
        peHours: parsedData[`value${i * 22 + 4}`],
        feHours: parsedData[`value${i * 22 + 5}`],
        rpm: parsedData[`value${i * 22 + 6}`],
        gear: parsedData[`value${i * 22 + 7}`],
        j1939FuelRate: parsedData[`value${i * 22 + 8}`],
        j1939EngineLoad: parsedData[`value${i * 22 + 9}`],
        j1939DualFuelRatio: parsedData[`value${i * 22 + 10}`],
        totalFuelUsed: parsedData[`value${i * 22 + 11}`],
        fan_speed: parsedData[`value${i * 22 + 12}`],
        trans_lockup: parsedData[`value${i * 22 + 13}`],
        discharge_pressure: parsedData[`value${i * 22 + 14}`],
        engine_temp: parsedData[`value${i * 22 + 15}`],
        engine_oil_pressure: parsedData[`value${i * 22 + 16}`],
        trans_temp: parsedData[`value${i * 22 + 17}`],
        trans_pressure: parsedData[`value${i * 22 + 18}`],
        pe_temp: parsedData[`value${i * 22 + 19}`],
        pe_pressure: parsedData[`value${i * 22 + 20}`],
        gas_supply_pressure: parsedData[`value${i * 22 + 21}`],
        gas_supply_temperature: parsedData[`value${i * 22 + 22}`]
      })).filter(unit => unit.unitNumber !== 0);
    };

    const subscribeToFleet = (fleetName, topic) => {
      return PubSub.subscribe(topic).subscribe({
        next: data => {
          try {
            const parsedData = data.value;
            const units = parseData(parsedData);
            setFleetData(prevData => ({ ...prevData, [fleetName]: units }));
            console.log(units);
          } catch (error) {
            console.error('Error parsing JSON data:', error);
          }
        },
        error: error => console.error(error),
        close: () => console.log(`${topic} Done`)
      });
    };

    Amplify.configure({
      Auth: {
        identityPoolId: process.env.REACT_APP_POOL_ID,
        region: process.env.REACT_APP_REGION
      }
    });

    Amplify.addPluggable(new AWSIoTProvider({
      aws_pubsub_region: process.env.REACT_APP_PUBSUB_REGION,
      aws_pubsub_endpoint: process.env.REACT_APP_PUBSUB_ENDPOINT
    }));

    const subscriptions = [
      subscribeToFleet('fleet1', 'pumpData'),
      subscribeToFleet('fleet2', 'pumpData2'),
      subscribeToFleet('fleet3', 'pumpData3'),
      subscribeToFleet('fleet4', 'pumpData4')
    ];

    return () => subscriptions.forEach(sub => sub.unsubscribe());
  }, []);

  const renderTableRows = (data) => data.map((unit, index) => (
    <tr key={index}>
      <td>{unit.inlineNumber}</td>
      <td>{unit.unitNumber}</td>
      <td>{unit.engineHours}</td>
      <td>{unit.transmissionHours}</td>
      <td>{unit.peHours}</td>
      <td>{unit.feHours}</td>
      <td>{unit.rpm}</td>
      <td>{unit.gear}</td>
      <td>{unit.j1939FuelRate}</td>
      <td>{unit.j1939EngineLoad}</td>
      <td>{unit.j1939DualFuelRatio}</td>
      <td>{unit.totalFuelUsed}</td>
      <td>{unit.fan_speed}</td>
      <td>{unit.trans_lockup}</td>
      <td>{unit.discharge_pressure}</td>
      <td>{unit.engine_temp}</td>
      <td>{unit.engine_oil_pressure}</td>
      <td>{unit.trans_temp}</td>
      <td>{unit.pe_pressure}</td>
      <td>{unit.pe_temp}</td>
      <td>{unit.pe_pressure}</td>
      <td>{unit.gas_supply_pressure}</td>
      <td>{unit.gas_supply_temperature}</td>
    </tr>
  ));

  const renderTable = (fleetName) => (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>In-line Position</th>
          <th>Unit Number</th>
          <th>Engine Hours</th>
          <th>Transmission Hours</th>
          <th>PE Hours</th>
          <th>FE Hours</th>
          <th>RPM</th>
          <th>Gear</th>
          <th>Fuel Rate</th>
          <th>Engine Load</th>
          <th>Dual Fuel Ratio</th>
          <th>Total Fuel Used</th>
          <th>Fan Speed</th>
          <th>Trans Lockup</th>
          <th>Discharge Pressure</th>
          <th>Engine Temp</th>
          <th>Engine Oil Pressure</th>
          <th>Trans Temp</th>
          <th>Trans Pressure</th>
          <th>PE Temp</th>
          <th>PE Pressure</th>
          <th>Gas Supply Pressure</th>
          <th>Gas Supply Temperature</th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows(fleetData[fleetName])}
      </tbody>
    </Table>
  );

  return (
    <Fragment>
      <Navbar />
      <div className="pumpContainer">
        <h1 className='pumpContainerHeader'>Pump Data Live Feed</h1>
        <Accordion className='pumpDropDown'>
          {Object.entries(fleetData).map(([name, data], index) => (
            <Accordion.Item eventKey={`${index}`} key={name}>
              <Accordion.Header>Fleet {index + 46}</Accordion.Header>
              <Accordion.Body>
                {renderTable(name)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div> 
    </Fragment>
  );
};

export default LiveViewer;
