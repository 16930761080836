import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const MechanicalIssueForm = ({ showMech, handleCloseMech, handleShowCommentsModal, setCollectedData, fleetData  }) => {
  const [inlinePump, setInlinePump] = useState('');
  const [mechanicalIssue, setMechanicalIssue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (!inlinePump || !mechanicalIssue) {
      setErrorMessage('All fields are required.');
      return;
    }
    setErrorMessage('');
    setCollectedData({ inlinePump, mechanicalIssue });
    handleCloseMech();
    handleShowCommentsModal();
  };

  return (
    <Modal show={showMech} onHide={handleCloseMech}>
      <Modal.Header closeButton>
        <Modal.Title>Pick Mechanical Issue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="formInlinePump">
            <Form.Label>Pick In-Line Pump</Form.Label>
            <Form.Control as="select" value={inlinePump} onChange={(e) => setInlinePump(e.target.value)}>
              <option value="">Select...</option>
              {fleetData && fleetData.map((unitNumber, index) => (
                <option key={index} value={unitNumber}>{unitNumber}</option>
              ))}
          </Form.Control>
          </Form.Group>
          <Form.Group controlId="formMechanicalIssue">
            <Form.Label>Mechanical Issue</Form.Label>
            <Form.Control as="select" value={mechanicalIssue} onChange={(e) => setMechanicalIssue(e.target.value)}>
              <option value="">Select...</option>
              <option value="Power End">Power End</option>
              <option value="Fluid End">Fluid End</option>
              <option value="Transmission">Transmission</option>
              <option value="Engine">Engine</option>
              <option value="Hydraulic">Hydraulic</option>
           </Form.Control>
          </Form.Group>
         
          <div className="d-flex justify-content-between mt-3">
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <Button variant="secondary" onClick={handleCloseMech}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Input Mechanical
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
  
export default MechanicalIssueForm; 