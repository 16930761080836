import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { Amplify, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import Navbar from './Navbar';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import MaintenanceInputForm from './MaintenanceInputForm';
import MechanicalIssueForm from './MechanicalIssueForm';
import CommentsModal from './CommentsForm';
import UpdateConstantForm from './UpdateConstantForm.js';
import HoleDetails from './HoleDetails.js';

const PUMPMAINT_URL = "https://astpumpdatabackend.com/pumpmaint/seats&valves&packing/";
const PUMPMAINT_INSERT_URL = "https://astpumpdatabackend.com/pumpmaint?";
const PUMPMECH_INSERT_URL = "https://astpumpdatabackend.com/pumpmech?";
const PUMPMAINT_PrevURL = "https://astpumpdatabackend.com/pumpmaint/seats&valves/prevRecords/";

const useMaintenanceData = (unitNumber) => {
  const [data, setData] = useState({ seats: [0, 0, 0, 0, 0], valves: [0, 0, 0, 0, 0], packing: [0, 0, 0, 0, 0] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${PUMPMAINT_URL}${unitNumber}`, {
          headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
          }
        });
        setData(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [unitNumber]);

  return data;
};

const PostPumpMaintenance = async (queryParams) => {
  try {
    await axios.post(`${PUMPMAINT_INSERT_URL}${queryParams}`, null, {
      headers: {  
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
      },
    });
    console.log('PUMPMAINT Data posted successfully');
  } catch (err) {
    console.error('Error posting data', err);
  }
};

const PostMechanicalIssue = async (queryParams) => {
  try {
    await axios.post(`${PUMPMECH_INSERT_URL}${queryParams}`, null, {
      headers: {  
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
      },
    });
    console.log('PUMPMECH Data posted successfully');
  } catch (err) {
    console.error('Error posting data', err);
  }
};

const lastedHrs = async (fleetNum, maintIssue, holeNumber) => {
  try {
    const response = await axios.get(`${PUMPMAINT_PrevURL}${fleetNum}/${maintIssue}/${holeNumber}`, {
      headers: {
        Authorization: `Bearer ${localStorage.AccessToken}`,
      }
    });
    console.log("response",response.data);
    const prevRecord = response.data;
    if (prevRecord ) {
      return prevRecord.record.lastHours;
    } else {
      return 0; // or any default value you consider appropriate
    }
  } catch (error) {
    console.error("Error fetching previous records:", error);
    return 0; // or any default value you consider appropriate
  }
};

const MaintenanceRow = ({ unit }) => {
  const { seats, valves, packing } = useMaintenanceData(unit.unitNumber);
  const constantValve = parseFloat(localStorage.getItem('constantValve'));
  const constantSeat = parseFloat(localStorage.getItem('constantSeat'));
  const constantPack = parseFloat(localStorage.getItem('constantPack'));
  const [showHole, setShowHole] = useState(false);
  const [holeData, setHoleData] = useState({});

  const handleCloseHole = useCallback(() => setShowHole(false), []);

  const calculateRemainingHours = (constant, totalHrs) => (constant - (unit.transmissionHours - totalHrs)).toFixed(2);

  const getColorByValue = (value) => {
    if (value >= 10) {
      return 'green';
    } else if (value > 0) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  const handleShowHoleDetails = async (unitNumber, maintIssue, holeNumber, hrs) => {
    setShowHole(true);
    setHoleData({ unitNumber, maintIssue, holeNumber, hrs });
  };

  const renderedValves = useMemo(() => valves.map((valve, index) => {
    const remainingHours = calculateRemainingHours(constantValve, valve);
    return (
      <Button
        className='td'
        key={`valve-${index}`}
        variant="link"
        onClick={() => handleShowHoleDetails(unit.unitNumber, 'Valves', index + 1, unit.transmissionHours)}
        style={{
          display: 'block',
          width: '100%',
          padding: '3px',
          margin: '5px 0',
          borderRadius: '5px',
          backgroundColor: '#f8f9fa',
          color: getColorByValue(remainingHours),
          textAlign: 'center',
          textDecoration: 'none',
          border: `1px solid ${getColorByValue(remainingHours)}`,
        }}
      >
        Hole {index + 1} <br /> {remainingHours}
      </Button>
    );
  }), [valves, constantValve, unit.transmissionHours]);

  const renderedSeats = useMemo(() => seats.map((seat, index) => {
    const remainingHours = calculateRemainingHours(constantSeat, seat);
    return (
      <Button
        className='td'
        key={`seat-${index}`}
        variant="link"
        onClick={() => handleShowHoleDetails(unit.unitNumber, 'Seats', index + 1, unit.transmissionHours)}
        style={{
          display: 'block',
          width: '100%',
          padding: '3px',
          margin: '5px 0',
          borderRadius: '5px',
          backgroundColor: '#f8f9fa',
          color: getColorByValue(remainingHours),
          textAlign: 'center',
          textDecoration: 'none',
          border: `1px solid ${getColorByValue(remainingHours)}`,
        }}
      >
        Hole {index + 1} <br /> {remainingHours}
      </Button>
    );
  }), [seats, constantSeat, unit.transmissionHours]);

  const renderedPacking = useMemo(() => packing.map((pack, index) => {
    const remainingHours = calculateRemainingHours(constantPack, pack);
    return (
      <Button
        className="td"
        key={`packing-${index}`}
        variant="link"
        onClick={() => handleShowHoleDetails(unit.unitNumber, 'Packing', index + 1, unit.transmissionHours)}
        style={{
          display: 'block',
          width: '100%',
          padding: '3px',
          margin: '5px 0',
          borderRadius: '5px',
          backgroundColor: '#f8f9fa',
          color: getColorByValue(remainingHours),
          textAlign: 'center',
          textDecoration: 'none',
          border: `1px solid ${getColorByValue(remainingHours)}`,
        }}
      >
        Hole {index + 1} <br /> {remainingHours}
      </Button>
    );
  }), [packing, constantPack, unit.transmissionHours]);

  return (
    <>
      <HoleDetails
        show={showHole}
        handleCloseHole={handleCloseHole}
        holeData={holeData}
      />
      <tr>
        <td>{unit.inlineNumber}</td>
        <td>{unit.unitNumber}</td>
        <td><h7 style={{display : 'flex', gap: '5px'}}>{renderedValves}</h7></td>
        <td><h7 style={{display : 'flex', gap: '5px'}}>{renderedSeats}</h7></td>
        <td><h7 style={{display : 'flex', gap: '5px'}}>{renderedPacking}</h7></td>
      </tr>
    </>
  );
};

const PumpMaintenance = ({ readPermission, writePermission, deleteFilePermission, deleteFolderPermission }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalMech, setShowModalMech] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [constantValve, setConstantValve] = useState(50);
  const [constantSeat, setConstantSeat] = useState(50);
  const [constantPack, setConstantPack] = useState(50);
  const [showUpdateConstant, setShowUpdateConstant] = useState(false);
  const [pumpNotFound, setPumpNotFound] = useState(false);
  const [fleetData, setFleetData] = useState({ fleet1: [], fleet2: [], fleet3: [], fleet4: [] });
  const [selectedFleetData, setSelectedFleetData] = useState([]);
  const [selectedFleetNum, setSelectedFleetNum] = useState([]);
  const [collectedData, setCollectedData] = useState({});

  const handleShow = useCallback(() => setShowModal(true), []);
  const handleClose = useCallback(() => setShowModal(false), []);
  const handleShowMech = useCallback(() => setShowModalMech(true), []);
  const handleCloseMech = useCallback(() => setShowModalMech(false), []);
  const handleShowCommentsModal = useCallback(() => setShowCommentsModal(true), []);
  const handleCloseCommentsModal = useCallback(() => setShowCommentsModal(false), []);
  const handleShowConstant = useCallback(() => setShowUpdateConstant(true), []);
  const handleCloseConstant = useCallback(() => setShowUpdateConstant(false), []);
  const handlePumpNotFoundClose = useCallback(() => setPumpNotFound(false), []);

  const handleSubmitConstant = useCallback((data) => {
    setConstantValve(data.valValve);
    setConstantSeat(data.valSeat);
    setConstantPack(data.valPack);
  }, []);

  useEffect(() => {
    const storedValve = localStorage.getItem('constantValve');
    if (storedValve) {
      setConstantValve(parseFloat(storedValve));
    }
    const storedSeat = localStorage.getItem('constantSeat');
    if (storedSeat) {
      setConstantSeat(parseFloat(storedSeat));
    }
    const storedPack = localStorage.getItem('constantPack');
    if (storedPack) {
      setConstantPack(parseFloat(storedPack));
    }
  }, []);

  const fleets = ["fleet1", "fleet2", "fleet3", "fleet4"];
  const options = useMemo(() => ({
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }), []);

  const handleCommentsSubmit = useCallback(async (formData) => {
    let found = false;
    for (const fleet of fleets) {
      for (const unit of fleetData[fleet]) {
        if (unit.unitNumber == formData.inlinePump) {
          found = true;
          const baseQueryParams = {
            pumpnumber: unit.unitNumber,
            pumporder: unit.inlineNumber,
            totalhrs: unit.transmissionHours,
            datechanged: new Date().toLocaleString('en-US', options),
            comments: formData.comments,
          };

          if ("brand" in formData) {
            baseQueryParams.brand = formData.brand;
            baseQueryParams.maintissue = formData.maintenanceIssue;
            baseQueryParams.fleet = formData.fleetNum;
            baseQueryParams.pmorfailure = formData.pmOrFailure;
            baseQueryParams.district = "Midland";
            if (!formData.holeNumbers.length) {
              const queryParams = new URLSearchParams({
                ...baseQueryParams,
                holenumber: 0,
              });
              PostPumpMaintenance(queryParams.toString());
            } else {
              for (const holenumber of formData.holeNumbers) {
                const lasthrs = await lastedHrs(formData.inlinePump, formData.maintenanceIssue, holenumber.value);
                const queryParams = new URLSearchParams({
                  ...baseQueryParams,
                  holenumber: holenumber.value,
                  partslife: lasthrs>0 ? (unit.transmissionHours - lasthrs).toFixed(2) : '',
                });
                PostPumpMaintenance(queryParams.toString());
              }
            }
          } else {
            baseQueryParams.mechissue = formData.mechanicalIssue;
            const queryParams = new URLSearchParams(baseQueryParams);
            PostMechanicalIssue(queryParams.toString());
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return;
        }
      }
    }
    if (!found) {
      setPumpNotFound(true);
    }
  }, [fleets, fleetData, options]);

  useEffect(() => {
    const parseData = (parsedData) => {
      return Array.from({ length: 24 }, (_, i) => ({
        inlineNumber: i + 1,
        unitNumber: parsedData[`value${i * 22 + 1}`],
        engineHours: parsedData[`value${i * 22 + 2}`],
        transmissionHours: parsedData[`value${i * 22 + 3}`],
        peHours: parsedData[`value${i * 22 + 4}`],
        feHours: parsedData[`value${i * 22 + 5}`],
        rpm: parsedData[`value${i * 22 + 6}`],
        gear: parsedData[`value${i * 22 + 7}`],
        j1939FuelRate: parsedData[`value${i * 22 + 8}`],
        j1939EngineLoad: parsedData[`value${i * 22 + 9}`],
        j1939DualFuelRatio: parsedData[`value${i * 22 + 10}`],
        totalFuelUsed: parsedData[`value${i * 22 + 11}`],
        fan_speed: parsedData[`value${i * 22 + 12}`],
        trans_lockup: parsedData[`value${i * 22 + 13}`],
        discharge_pressure: parsedData[`value${i * 22 + 14}`],
        engine_temp: parsedData[`value${i * 22 + 15}`],
        engine_oil_pressure: parsedData[`value${i * 22 + 16}`],
        trans_temp: parsedData[`value${i * 22 + 17}`],
        trans_pressure: parsedData[`value${i * 22 + 18}`],
        pe_temp: parsedData[`value${i * 22 + 19}`],
        pe_pressure: parsedData[`value${i * 22 + 20}`],
        gas_supply_pressure: parsedData[`value${i * 22 + 21}`],
        gas_supply_temperature: parsedData[`value${i * 22 + 22}`]
      })).filter(unit => unit.unitNumber !== 0);
    };

    const subscribeToFleet = (fleetName, topic) => {
      return PubSub.subscribe(topic).subscribe({
        next: data => {
          try {
            const parsedData = data.value;
            const units = parseData(parsedData);
            setFleetData(prevData => ({ ...prevData, [fleetName]: units }));
          } catch (error) {
            console.error('Error parsing JSON data:', error);
          }
        },
        error: error => console.error(error),
        close: () => console.log(`${topic} Done`)
      });
    };

    Amplify.configure({
      Auth: {
        identityPoolId: process.env.REACT_APP_POOL_ID,
        region: process.env.REACT_APP_REGION
      }
    });

    Amplify.addPluggable(new AWSIoTProvider({
      aws_pubsub_region: process.env.REACT_APP_PUBSUB_REGION,
      aws_pubsub_endpoint: process.env.REACT_APP_PUBSUB_ENDPOINT
    }));

    const subscriptions = [
      subscribeToFleet('fleet1', 'pumpData'),
      subscribeToFleet('fleet2', 'pumpData2'),
      subscribeToFleet('fleet3', 'pumpData3'),
      subscribeToFleet('fleet4', 'pumpData4')
    ];

    return () => subscriptions.forEach(sub => sub.unsubscribe());
  }, []);

  const handleAccordionSelect = useCallback((eventKey) => {
    const fleetName = `fleet${parseInt(eventKey) + 1}`;
    if (fleetData[fleetName]) {
      setSelectedFleetData(fleetData[fleetName].map(unit => unit.unitNumber));
      setSelectedFleetNum(parseInt(eventKey) + 46);
    } else {
      setSelectedFleetData([]);
    }
  }, [fleetData]);

  const renderTableRows = useCallback((data) => data.map((unit, index) => (
    <MaintenanceRow key={index} unit={unit} />
  )), []);

  const renderTable = useCallback((fleetName) => (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>In-line Position</th>
          <th>Unit Number</th>
          <th>Valve Hours Remaining</th>
          <th>Seat Hours Remaining</th>
          <th>Packing Hours Remaining</th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows(fleetData[fleetName])}
      </tbody>
    </Table>
  ), [fleetData, renderTableRows]);

  return (
    <Fragment>
      <Navbar />
      <div style={{ width: 'fit-content' }}>
        {writePermission &&
          <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
            <Button variant="primary" onClick={handleShow}>
              Insert Maintenance
            </Button>
            <Button variant="primary" onClick={handleShowMech}>
              Mechanical Issue
            </Button>
            <Button variant="primary" onClick={handleShowConstant}>
              Update Constant
            </Button>
          </div>
        }

        <MaintenanceInputForm
          show={showModal}
          handleClose={handleClose}
          handleShowCommentsModal={handleShowCommentsModal}
          setCollectedData={setCollectedData}
          fleetData={selectedFleetData}
          fleetNum={selectedFleetNum}
        />
        <CommentsModal
          show={showCommentsModal}
          handleCloseComments={handleCloseCommentsModal}
          handleSubmitComments={handleCommentsSubmit}
          collectedData={collectedData}
        />

        <MechanicalIssueForm
          showMech={showModalMech}
          handleCloseMech={handleCloseMech}
          handleShowCommentsModal={handleShowCommentsModal}
          setCollectedData={setCollectedData}
          fleetData={selectedFleetData}
        />

        <UpdateConstantForm
          show={showUpdateConstant}
          currValve={constantValve}
          currSeat={constantSeat}
          currPack={constantPack}
          handleCloseConstant={handleCloseConstant}
          handleSubmitConstant={handleSubmitConstant}
        />
      </div>
      <div className="pumpContainer">
        <h1 className='pumpContainerHeader'>Pump Maintenance</h1>
        <Accordion className='pumpDropDown' onSelect={handleAccordionSelect}>
          {Object.entries(fleetData).map(([name, data], index) => (
            <Accordion.Item eventKey={`${index}`} key={name}>
              <Accordion.Header>Fleet {index + 46}</Accordion.Header>
              <Accordion.Body>
                {renderTable(name)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <Modal show={pumpNotFound} onHide={handlePumpNotFoundClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pump Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The specified pump was not found.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePumpNotFoundClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PumpMaintenance;
