import React, { Fragment } from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

function Login() {
  return (
    <Fragment>
        <LoginButton />
        <LogoutButton />
    </Fragment>
  );
}

export default Login;