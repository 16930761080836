import React, { Fragment, useState, useEffect } from 'react';
import jsQR from 'jsqr'
import Resizer from "react-image-file-resizer";
import AWS from 'aws-sdk';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { ButtonDropdown, DropdownMenu, DropdownToggle, Form, FormGroup, Input } from 'reactstrap';
import Navbar from './Navbar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from 'react-loading';

const FileManipulation = ({ readPermission, writePermission, deleteFilePermission, deleteFolderPermission }) => {

  const [rawData, setRawData] = useState([]);
  const [files, setFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [selectedPictures, setSelectedPictures] = useState([])
  const [showManipulation, setShowManipulation] = useState(true);
  const [folderFiles, setFolderFiles] = useState([]);
  const [folderFileToDelete, setFolderFileToDelete] = useState('');
  const [dropdownOpen, setOpen] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  });
  const [imageUrls, setImageUrls] = useState([]);
  const [imageObjects, setImageObjects] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showTickets, setShowTickets] = useState('Hide Tickets');
  const [showDownload, setShowDownload] = useState('Show Download Options');
  const [uploadLoading, setUploadLoading] = useState(false);


  const fetchFiles = async () => {
    
    try {
      // Replace 'YOUR_S3_BUCKET_NAME', 'YOUR_ACCESS_KEY', and 'YOUR_SECRET_ACCESS_KEY' with your actual S3 bucket name and access key credentials
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_KEY_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });

      const response = await s3.listObjectsV2({
        Bucket: 'astsand',
      }).promise();

      setRawData(response.Contents);
      const folders = response.Contents.filter(file => file.Key.endsWith('/') && file.Size === 0);
      setFiles(folders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const createFolder = async () => {
    try {
      // Replace 'YOUR_S3_BUCKET_NAME' with your actual S3 bucket name
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_KEY_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });

      const params = {
        Bucket: 'astsand',
        Key: `${folderName}/`,
      };

      await s3.putObject(params).promise();
      console.log(`Folder ${folderName} created successfully in S3 bucket.`);

      // Refresh the file list
    } catch (error) {
      console.error('Error creating folder in S3:', error);
    }
    toggle();
    setFolderName('')
    fetchFiles();
  };

  const deleteFolderFilePopUp = async (folderKey) => {
    // Set the folder to be deleted and show the confirmation pop-up
    setFolderFileToDelete(folderKey);
    setShowDeleteConfirmation(true);
  };

  const cancelDeleteFolder = () => {
    // Clear the folderToDelete and hide the confirmation pop-up
    setFolderFileToDelete('');
    setShowDeleteConfirmation(false);
  };

  const deleteFolderOrFile = async () => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_KEY_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });
  
      // Step 1: Delete objects within the folder
      const objects = await s3.listObjectsV2({
        Bucket: 'astsand',
        Prefix: folderFileToDelete,
      }).promise();
  
      if (objects.Contents.length > 0) {
        const deleteParams = {
          Bucket: 'astsand',
          Delete: {
            Objects: objects.Contents.map((obj) => ({ Key: obj.Key })),
          },
        };
  
        await s3.deleteObjects(deleteParams).promise();
      }
  
      // Step 2: Delete the folder itself
      const deleteFolderParams = {
        Bucket: 'astsand',
        Key: folderFileToDelete,
      };
  
      await s3.deleteObject(deleteFolderParams).promise();
      console.log(`Folder ${folderFileToDelete} deleted successfully from S3 bucket.`);
  
    } catch (error) {
      console.error('Error deleting folder from S3:', error);
    }
    setShowDeleteConfirmation(false);
    if(folderFiles !== []){
      const updatedFolderFiles = folderFiles.filter(array => array.Key !== folderFileToDelete);
      setFolderFiles(updatedFolderFiles);
    }
    
    if (files.some(file => file.Key === folderFileToDelete)) {
      const updatedFiles = files.filter(file => file.Key !== folderFileToDelete);
      setFiles(updatedFiles);
    }
  };

  const uploadMultiplePictures = async (folderName, pictures) => {
    setUploadLoading(true);
    try {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_KEY_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });

      const uploadPromises = pictures.map(async (file) => {
        const QrResults = await QRScanner(file);
        console.log(QrResults);
        const resizedPicture = await resizeImage(file);
        if(QrResults===null){
          console.log("No QR results. Replacing file name with dummy name.")
          let ticketName = file.name
          console.log(ticketName);
    
          const uploadParams = {
            Bucket: 'astsand',
            Key: `${folderName}${ticketName}`,
            Body: resizedPicture,
          };
          return s3.upload(uploadParams).promise();
        }
        else{
        let ticketName = QrResults.split(' | ');
        ticketName = ticketName[0] + '-' + ticketName[2] + '-' + ticketName[4] + '-' + ticketName[5]+'.JPEG';
        console.log(ticketName);
  
        const uploadParams = {
          Bucket: 'astsand',
          Key: `${folderName}${ticketName}`,
          Body: resizedPicture,
        };
        return s3.upload(uploadParams).promise();
    }});
  
      await Promise.all(uploadPromises);
      console.log(`Pictures uploaded successfully to the ${folderName} folder.`);
    } catch (error) {
      console.error('Error uploading pictures to S3:', error);
    }
    setUploadLoading(false);
  };
  
  const resizeImage = async (picture) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        picture,
        1500,
        1500,
        'JPEG',
        80,
        0,
        (compressedFile) => resolve(compressedFile),
        'file' // Specify output type as 'file'
      );
    });
  };
  
  const QRScanner = (imageFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
  
      reader.onload = async () => {
        try {
          const imageDataUrl = reader.result;
          const img = new Image();
          img.src = imageDataUrl;
  
          img.onload = async () => {
            // Use createImageBitmap to improve QR code recognition (better compatibility with different image qualities)
            const bitmap = await createImageBitmap(img);
  
            // Get the image data from the ImageBitmap
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            context.drawImage(bitmap, 0, 0);
  
            // Get the image data from the canvas
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  
            // Use jsQR to scan for QR codes directly from the image data
            const code = jsQR(imageData.data, imageData.width, imageData.height);
  
            if (code) {
              resolve(code.data); // Resolve the promise with the detected QR code data
            } else {
              resolve(null); // Resolve with null if no QR code is found in the image
            }
          };
        } catch (error) {
          console.error('Error scanning QR code:', error);
          resolve(null); // Resolve with null in case of an error
        }
      };
  
      reader.readAsDataURL(imageFile);
    });
  };
  
  const handleUpload = async (folder) => {
    // Perform any necessary validations or checks
  
    try {
      // Upload selected pictures to the folder
      await uploadMultiplePictures(folder, selectedPictures);
  
      // Perform any additional actions after the upload
      console.log('Pictures uploaded successfully.');
    } catch (error) {
      console.error('Error handling picture upload:', error);
    }
  };
  
  const fetchFilesInFolder = async (folderName) => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_KEY_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });

      const response = await s3.listObjectsV2({
        Bucket: 'astsand',
        Prefix: `${folderName}`,
      }).promise();

      const fileNames = response.Contents.filter(array => array.Key !== folderName);
      fileNames.sort((a, b) => b.LastModified - a.LastModified);
      setFolderFiles(fileNames);

      // const filteredDateFiles = fileNames.filter(item => {
      //   const lastModified = new Date(item.LastModified);
      //   return lastModified > startDate && lastModified < endDate;
      // });

      const imagePromises = fileNames.map((fileKey) => {
        const params = {
          Bucket: 'astsand',
          Key: fileKey.Key,
        };
        return s3.getObject(params).promise();
      }); 

      try {
        const imageResponses = await Promise.all(imagePromises);
        imageResponses.sort((a, b) => b.LastModified - a.LastModified);
        setImageObjects(imageResponses);
        const fetchedImageUrls = imageResponses.filter(file => file.LastModified>startDate && file.LastModified<endDate).map((response) =>
          URL.createObjectURL(new Blob([response.Body]))
        );
        setImageUrls(fetchedImageUrls);
      } catch (error) {
        console.log('Error retrieving images:', error);
      }

    } catch (error) {
      console.log(`Error fetching files in ${folderName} folder:`, error);
    }
  };

  const imageURLDateChange = (start, end) => {
    const fetchedImageUrls = imageObjects.filter(file => file.LastModified>start && file.LastModified<end).map((response) =>
    URL.createObjectURL(new Blob([response.Body]))
  );
    setImageUrls(fetchedImageUrls);
  }
  
  const handleDownloadFile = (fileKey) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_KEY_ID,
      secretAccessKey: process.env.REACT_APP_KEY,
    });

    const params = {
      Bucket: 'astsand',
      Key: fileKey,
    };

    s3.getObject(params, (error, data) => {
      if (error) {
        console.log(`Error downloading file: ${error}`);
      } else {
        // Create a download link
        const downloadUrl = URL.createObjectURL(new Blob([data.Body]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileKey.substring(fileKey.lastIndexOf('/') + 1));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleDownloadAllFiles = async () => {
    try {
      const zip = new JSZip();
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_KEY_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });

      // Iterate through each file in the folderFiles state and add them to the ZIP archive
      for (const file of folderFiles) {
        const params = {
          Bucket: 'astsand',
          Key: file.Key,
        };

        const data = await s3.getObject(params).promise();

        // Add the file to the ZIP archive
        zip.file(file.Key.substring(file.Key.lastIndexOf('/') + 1), data.Body);
      }

      // Generate the ZIP file
      const zipContent = await zip.generateAsync({ type: 'blob' });

      // Save the ZIP file
      FileSaver.saveAs(zipContent, 'DownloadedFiles.zip');

      console.log('All files downloaded successfully.');
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  };

  const toggle = () => setOpen(!dropdownOpen);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const picture = [];
  
    files.forEach((file) => {
      const fileType = file.type;
      const validExtensions = ["image/jpeg", "image/png", "application/pdf"]; // Add more valid image file types if needed
  
      // Check if the file type is a valid image file type
      if (validExtensions.includes(fileType)) {
        picture.push(file);
      }
    });
    setSelectedPictures(picture);
  };
  

  const handleFolderNameChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleViewTickets = (folderName) => {
    console.log(endDate)
    fetchFilesInFolder(folderName);
    setShowManipulation(false);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    imageURLDateChange(start,end);
    ;
  };

  const ticketToggle = () => {
    if (showTickets === "Hide Tickets") {
      setShowTickets("Show Tickets")
    }
    if (showTickets === "Show Tickets") {
      setShowTickets("Hide Tickets")
    }
  }

  const downloadToggle = () => {
    if (showDownload === "Hide Download Options") {
      setShowDownload("Show Download Options")
    }
    if (showDownload === "Show Download Options") {
      setShowDownload("Hide Download Options")
    }
  }

  return (

    <Fragment>
      { uploadLoading &&
        <div className="loader-spin">
          <ReactLoading type={'spin'} color={'cyan'} />
        </div>}
      <Navbar/>
        {readPermission ||
          <h1>
            No Access Allowed. Please Contact AST for Credentials.
          </h1>
        }
        {readPermission && 
        <Fragment>
          { showManipulation &&
          <div>
            {writePermission &&
            <div>
              <ButtonDropdown className="create-pad" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="create-folder" caret>
                  Create Folder
                </DropdownToggle>
                <DropdownMenu className="create-salesman-dropdown">
                <Form>
                  <FormGroup>
                    <Input type="text" value={folderName} id="folder-name" placeholder="Input Folder Name" onChange={handleFolderNameChange}/>
                    <Button variant="primary" onClick={createFolder}>
                      Create Folder
                    </Button>
                  </FormGroup>
                </Form>
                </DropdownMenu>
              </ButtonDropdown>
            </div>}
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Pad Name</th>
                  <th>Total Sand Tickets</th>
                  <th>Last Upload Date</th>
                  {writePermission &&
                  <Fragment>
                  <th>Select Sand Tickets</th>
                  <th>Submit Sand Tickets</th>
                  </Fragment>}
                  <th>View Sand Tickets</th>
                  {deleteFolderPermission &&
                  <th>Delete Folder</th>}
                </tr>
              </thead>
              <tbody>
                {files.map((files, index) => (
                  <tr key={index}>
                    <td>{files.Key.slice(0, -1)}</td>
                    <td>{rawData.filter(file => file.Key.includes(files.Key)).length - 1}</td>
                    <td>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(files.LastModified)}</td>
                    {writePermission && <td><input type="file" multiple onChange={handleFileChange}/></td>}
                    {writePermission &&  <td><Button variant="primary" onClick={() => handleUpload(files.Key)}>Upload</Button>{''}</td>}
                    <td><Button variant="secondary" onClick={() => handleViewTickets(files.Key)}>View Tickets</Button>{''}</td>
                    {deleteFolderPermission &&
                    <td><Button variant="danger" onClick={() => deleteFolderFilePopUp(files.Key)}>Delete Folder</Button>{' '}</td>}
                  </tr>
                ))}
              </tbody>
          </Table>
        </div>}
        { showManipulation ||
          <div> 
            <div>
              <div className="view-ticket-header">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChange}
                  isClearable={true}
                />
                <Button variant="primary" className="hide-tickets" onClick={(event) => ticketToggle(event.value)}>{showTickets}</Button>{''}
                <Button variant="secondary" onClick={(event) => downloadToggle(event.value)}>{showDownload}</Button>{''}
              </div>
              {showTickets ==="Hide Tickets" &&
              <div>
                <ul>
                  {imageUrls.map((imageUrl, index) => (
                    <Fragment>
                    <h2 className = 'ticket-header'>
                      Sand Ticket #{index+1} / Upload date: {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(folderFiles[index].LastModified)} 
                    </h2>
                    <li className={'sand-ticket-holder'}>
                      <img className={'sand-ticket-image'}
                          style={{ width: '100%' }}
                          key={index}
                          src={imageUrl}
                          alt={`${index + 1}`} />
                    </li>     
                    </Fragment>  
                  ))}
                </ul>
              </div>}
            </div>
            {showDownload ==="Hide Download Options" &&
            <div>
              <div>
                <Button className="download-all-files" variant="primary" onClick={handleDownloadAllFiles} >Download All Files</Button>
              </div>
              <div>
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Img Size {`(MB)`}</th>
                      <th>Last Upload Date</th>
                      <th>Download Ticket</th>
                      {deleteFilePermission && <th>Delete Ticket</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {folderFiles.map((files, index) => (
                      <tr key={index}>
                        <td>{files.Key.slice(0, -1)}</td>
                        <td>{Math.round(files.Size/1000)}</td>
                        <td>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(files.LastModified)}</td>
                        <td><Button variant="primary" onClick={() => handleDownloadFile(`${files.Key}`)}>Download</Button></td>
                        {deleteFilePermission && <td><Button variant="danger" onClick={() => deleteFolderFilePopUp(files.Key)}>Delete</Button>{' '}</td>}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>}
        </div>}
        {showDeleteConfirmation && (
        <div className="delete-confirmation">
          <p>Are you sure you want to delete the folder and or file(s) ({folderFileToDelete.slice(0, -1)})? </p>
          <div className="delete-buttons">
            <Button variant="danger" onClick={deleteFolderOrFile}>
              Yes, delete
            </Button>
            <Button variant="secondary" onClick={cancelDeleteFolder}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      </Fragment>}
    </Fragment>
  );
};

export default FileManipulation;
