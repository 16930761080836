import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';

const PUMPMAINT_PrevURL = "https://astpumpdatabackend.com/pumpmaint/seats&valves/prevRecords/";

const HoleDetails = ({ show, handleCloseHole, holeData }) => {
  const [previousRecords, setPreviousRecords] = useState({ record: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (holeData) {
      const fetchPreviousRecords = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${PUMPMAINT_PrevURL}${holeData.unitNumber}/${holeData.maintIssue}/${holeData.holeNumber}`, {
            headers: {
              Authorization: `Bearer ${localStorage.AccessToken}`,
            }
          });
          console.log(response.data);
          setPreviousRecords(response.data);
        } catch (err) {
          console.log(err);
          setPreviousRecords({ record: null });
        } finally {
          setLoading(false);
        }
      };

      fetchPreviousRecords();
    }
  }, [holeData]);

  const handleDelete = useCallback(async (id) => {
    try {
      await axios.delete(`https://astpumpdatabackend.com/pumpmaint/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.AccessToken}`,
        }
      });
      handleCloseHole();
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Modal show={show} onHide={handleCloseHole}>
      <Modal.Header closeButton>
        <Modal.Title>{holeData.maintIssue} Maintenance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formInlinePump">
            <Form.Label>Pick In-Line Pump : &ensp;</Form.Label>
            <Form.Label><b>{holeData.unitNumber}</b></Form.Label>
          </Form.Group>
          <Form.Group controlId="formInlinePump">
            <Form.Label>Hole Number : &ensp;</Form.Label>
            <Form.Label><b>{holeData.holeNumber}</b></Form.Label>
          </Form.Group>
          <Form.Group controlId="formInlinePump">
            <Form.Label>Current Transmission Hours : &ensp;</Form.Label>
            <Form.Label><b>{holeData.hrs}</b></Form.Label>
          </Form.Group>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            previousRecords.record && (
              <>
                <Form.Group controlId="formInlinePump">
                  <Form.Label>Last Changed Hours : &ensp;</Form.Label>
                  <Form.Label><b>{previousRecords.record.lastHours}</b></Form.Label>
                </Form.Group>
                <Form.Group controlId="formInlinePump">
                  <Form.Label>Last Changed Time : &ensp;</Form.Label>
                  <Form.Label><b>{previousRecords.record.datechanged}</b></Form.Label>
                  <Button
                    style={{ height: 'fit-content', marginBlock: 'auto', float:'inline-end'}}
                    variant="danger"
                    onClick={() => handleDelete(previousRecords.record.id)}
                  >
                    Delete
                  </Button>
                </Form.Group>
              </>
            )
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default HoleDetails;
