import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();
  return (
    isAuthenticated && (
      <div>
        <button type="button" className="btn btn-primary" onClick={() => logout()}>Sign Out</button>
      </div>
    )
  );
};

export default LogoutButton;
