import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Highcharts from 'highcharts/highstock';
import highchartsBoost from 'highcharts/modules/boost';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import Navbar from './Navbar';
import "react-datepicker/dist/react-datepicker.css";

// Initialize the Boost module
highchartsBoost(Highcharts);

const PumpDataChart = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [chartData, setChartData] = useState([]);
    const [selectedPumps, setSelectedPumps] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const chartRef = useRef(null); // Add a ref to access the chart instance

    const columnOptions = [
        { value: 'engine_hours', label: 'Engine Hours' },
        { value: 'transmission_hours', label: 'Transmission Hours' },
        { value: 'pe_hours', label: 'PE Hours' },
        { value: 'fe_hours', label: 'FE Hours' },
        { value: 'rpm', label: 'RPM' },
        { value: 'gear', label: 'Gear' },
        { value: 'fuel_rate', label: 'Fuel Rate' },
        { value: 'engine_load', label: 'Engine Load' },
        { value: 'dual_fuel_ratio', label: 'Dual Fuel Ratio' },
        { value: 'fan_speed', label: 'Fan Speed' },
        { value: 'trans_lockup', label: 'Trans Lockup' },
        { value: 'discharge_pressure', label: 'Discharge Pressure' },
        { value: 'engine_temp', label: 'Engine Temperature' },
        { value: 'engine_oil_pressure', label: 'Engine Oil Pressure' },
        { value: 'trans_temp', label: 'Transmission Temperature' },
        { value: 'trans_pressure', label: 'Transmission Pressure' },
        { value: 'pe_temp', label: 'Powerend Temperature' },
        { value: 'pe_pressure', label: 'Powerend Pressure' },
        { value: 'gas_supply_pressure', label: 'Gas Supply Pressure' },
        { value: 'gas_supply_temperature', label: 'Gas Supply Temperature' },
    ];
    
    const pumpOptions = Array.from({ length: 201 }, (v, k) => ({
        value: 3600 + k, label: 3600 + k
    }));

    const loadData = async () => {
        const start = `${startDate.toISOString().split('T')[0]} ${startDate.toTimeString().split(' ')[0]}`;
        const end = `${endDate.toISOString().split('T')[0]} ${endDate.toTimeString().split(' ')[0]}`;
        console.log(start)
        console.log(end)

        const pumpNumbers = selectedPumps.map(pump => pump.value);
        const columns = selectedColumns.map(column => column.value);
        const queryParams = new URLSearchParams({
            pump_numbers: pumpNumbers,
            columns: columns,
            start_date: start,
            end_date: end
        });

        const response = await fetch(`https://astpumpdatabackend.com/get_data?${queryParams.toString()}`,{headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
    }});
        const data = await response.json();
        console.log(data)

        if (response.ok) {
            // Dynamically create chart series based on the selected pumps and columns
            let newSeries = [];
            selectedColumns.forEach(column => {
                selectedPumps.forEach(pump => {
                    const seriesData = data
                        .filter(item => parseInt(item.pump_number) === pump.value)
                        .map(item => [new Date(item.time_stamp).getTime(), item[column.value]]);
                    newSeries.push({
                        name: `${column.label} - Pump ${pump.label}`,
                        data: seriesData,
                        turboThreshold: 50000
                    });
                });
            });
            setChartData(newSeries); // Update chart data with the new series
        } else {
            console.error("Failed to fetch data:", data.error);
        }
    };

    const options = {
        chart: {
            type: 'spline',
            zoomType: 'x',
        },
        title: {
            text: 'Data Over Time'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'Date and Time'
            }
        },
        yAxis: {
            title: {
                text: 'Values'
            }
        },
        series: chartData, // Directly use the chartData state
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                }
            }
        },
        tooltip: {
            shared: true,
            split: false,
            useHTML: true, // Use HTML to render the tooltip for better formatting options
            formatter: function () {
                if (!this.points) return ""; // Handle no data points gracefully
        
                let s = `<b>${Highcharts.dateFormat('%A, %b %e, %Y %H:%M:%S', this.x)}</b>`;
                // Remove the slice to include all series points
                this.points.forEach((point) => {
                    s += `<br/><b>${point.series.name}</b>: ${point.y}`;
                });
                return s;
            },
            style: {
                pointerEvents: 'auto' // Enables scrolling inside the tooltip if using HTML
            }
        }
    };

    return (
        <div>
            <Navbar />
            <Select
                options={pumpOptions}
                onChange={setSelectedPumps}
                isMulti
                placeholder="Select Pump Numbers"
            />
            <Select
                options={columnOptions}
                onChange={setSelectedColumns}
                isMulti
                placeholder="Select Columns"
            />
            <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                showTimeSelect
                dateFormat="Pp"
            />
            <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                showTimeSelect
                dateFormat="Pp"
            />
            <button onClick={loadData}>Load Data</button>
            <HighchartsReact
                ref={chartRef} // Use the ref to access the Highcharts chart instance
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
            />
        </div>
    );
};

export default PumpDataChart;


