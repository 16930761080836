import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const CommentsModal = ({ show, handleCloseComments, handleSubmitComments, collectedData }) => {
    const [comments, setComments] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const onSubmit = (e) => {
        e.preventDefault();
        e.preventDefault();
        if (!comments) {
            setErrorMessage('All fields are required.');
            return;
        }
        console.log(collectedData);
        handleSubmitComments({ ...collectedData, comments });
        handleCloseComments();
       //window.location.reload();
      };

    return (
    <Modal show={show} onHide={handleCloseComments}>
        <Modal.Header closeButton>
        <Modal.Title>Enter Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="formComments">
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" value={comments} onChange={(e) => setComments(e.target.value)}>
                
            </Form.Control>
            </Form.Group>
           
            
            <div className="d-flex justify-content-between mt-3">
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <Button variant="secondary" onClick={handleCloseComments}>
                Cancel
            </Button>
            <Button variant="primary" type="submit">
                Submit
            </Button>
            </div>
        </Form>
        </Modal.Body>
    </Modal>
    );
};
    
export default CommentsModal; 
