import React, { Fragment } from "react";
import Login from "./Login";
import "./Login.css"
import logo from "./AST_Logo Clear.png"

function LoginPage() {
  return (
    <Fragment>
        <div className="container-loginPage">
            <div className="login">
                <img src={logo} alt="AST-Logo"/>
                <Login/>
            </div>
            <div className="iframe">
                <iframe src="https://advstimtech.com/about/" title="AST-About"></iframe>
            </div>
      </div>
    </Fragment>
  );
}

export default LoginPage;