import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';

const MaintenanceInputForm = ({ show, handleClose, handleShowCommentsModal, setCollectedData, fleetData, fleetNum }) => {
  const [inlinePump, setInlinePump] = useState('');
  const [maintenanceIssue, setMaintenanceIssue] = useState('');
  const [pmOrFailure, setPmOrFailure] = useState('')
  const [brand, setBrand] = useState('');
  const [holeNumbers, setHoleNumbers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const brandOptions = {
    Valves: [{ label: "A&W", value: "A&W" }],
    Seats: [{ label: "A&W", value: "A&W" }, { label: "Kerr", value: "Kerr" }],
    Packing: [{ label: "GD Redline", value: "GD Redline" }],
    "Air Filters": [{ label: "Donaldson", value: "Donaldson" }, { label: "CAT", value: "CAT" }],
    "Fuel Filters": [{ label: "Fleetguard", value: "Fleetguard" }, { label: "Donaldson", value: "Donaldson" }, { label: "CAT", value: "CAT" }],
  };

  useEffect(() => {
    if (!show) {
      setInlinePump('');
      setMaintenanceIssue('');
      setPmOrFailure('');
      setBrand('');
      setHoleNumbers([]);
      setErrorMessage('');
    }
  }, [show]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    if (!inlinePump || !maintenanceIssue || !pmOrFailure || !brand || (['Valves', 'Seats', 'Packing'].includes(maintenanceIssue) && holeNumbers.length === 0)) {
      setErrorMessage('All fields are required.');
      return;
    }
    setErrorMessage('');
    setCollectedData({ inlinePump, fleetNum, pmOrFailure, maintenanceIssue, brand, holeNumbers });
    handleClose();
    handleShowCommentsModal();
  }, [inlinePump, maintenanceIssue, pmOrFailure, brand, holeNumbers, setCollectedData, handleClose, handleShowCommentsModal]);

  const holeOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Pick Maintenance Issue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="formInlinePump">
            <Form.Label>Pick In-Line Pump</Form.Label>
            <Form.Control as="select" value={inlinePump} onChange={(e) => setInlinePump(e.target.value)}>
              <option value="">Select...</option>
              {fleetData && fleetData.map(unitNumber => (
                <option key={unitNumber} value={unitNumber}>{unitNumber}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formMaintenanceIssue">
            <Form.Label>Maintenance Issue</Form.Label>
            <Form.Control as="select" value={maintenanceIssue} onChange={(e) => setMaintenanceIssue(e.target.value)}>
              <option value="">Select...</option>
              <option value="Valves">Valves</option>
              <option value="Seats">Seats</option>
              <option value="Packing">Packing</option>
              <option value="Air Filters">Air Filters</option>
              <option value="Fuel Filters">Fuel Filters</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formPmOrFailure">
            <Form.Label>PM/Failure</Form.Label>
            <Form.Control as="select" value={pmOrFailure} onChange={(e) => setPmOrFailure(e.target.value)}>
              <option value="">Select...</option>
              <option value="PM">PM</option>
              <option value="Failure">Failure</option>
            </Form.Control>
          </Form.Group>
          {['Valves', 'Seats', 'Packing'].includes(maintenanceIssue) && (
            <Form.Group controlId="formHoleNumbers">
              <Form.Label>Hole Number</Form.Label>
              <MultiSelect
                options={holeOptions}
                value={holeNumbers}
                onChange={setHoleNumbers}
                labelledBy="Select Hole Numbers"
              />
            </Form.Group>
          )}
         
          
            <Form.Group controlId="formBrand">
              <Form.Label>Brand</Form.Label>
              <Form.Control as="select" value={brand} onChange={(e) => setBrand(e.target.value)}>
                <option value="">Select...</option>
                { maintenanceIssue && (brandOptions[maintenanceIssue].map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                )))}
              </Form.Control>
            </Form.Group>
          
          
          <div className="d-flex justify-content-between mt-3">
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Input Maintenance
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MaintenanceInputForm;
