import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

const UpdateConstantForm = ({ show, currValve, currSeat, currPack, handleCloseConstant, handleSubmitConstant }) => {
    const [valValve, setValValve] = useState(currValve);
    const [valSeat, setValSeat] = useState(currSeat);
    const [valPack, setValPack] = useState(currPack);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (show) {
            setValValve(currValve);
            setValSeat(currSeat);
            setValPack(currPack);
        }
    }, [show, currValve, currSeat, currPack]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (valValve === '' || valSeat === '' || valPack === '') {
            setErrorMessage('All fields are required.');
            return;
        }
        localStorage.setItem('constantValve', valValve);
        localStorage.setItem('constantSeat', valSeat);
        localStorage.setItem('constantPack', valPack);
        handleSubmitConstant({ valValve, valSeat, valPack });
        handleCloseConstant();
        window.location.reload();
    };

    return (
        <Modal show={show} onHide={handleCloseConstant}>
            <Modal.Header closeButton>
                <Modal.Title>Update Constant Value</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Form.Group controlId="formValve">
                                <Form.Label>Current Valve Constant:</Form.Label>
                                <Form.Control style={{marginTop : '0px'}}
                                    plaintext
                                    readOnly
                                    defaultValue={currValve}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formUpdateValve">
                                <Form.Label>Update:</Form.Label>
                                <Form.Control style={{marginTop : '0px'}}
                                    type="number"
                                    value={valValve}
                                    onChange={(e) => setValValve(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formSeat">
                                <Form.Label>Current Seat Constant:</Form.Label>
                                <Form.Control style={{marginTop : '0px'}}
                                    plaintext
                                    readOnly
                                    defaultValue={currSeat}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formUpdateSeat">
                                <Form.Label>Update:</Form.Label>
                                <Form.Control style={{marginTop : '0px'}}
                                    type="number"
                                    value={valSeat}
                                    onChange={(e) => setValSeat(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formPack">
                                <Form.Label>Current Pack Constant:</Form.Label>
                                <Form.Control style={{marginTop : '0px'}}
                                    plaintext
                                    readOnly
                                    defaultValue={currPack}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formUpdatePack">
                                <Form.Label>Update:</Form.Label>
                                <Form.Control style={{marginTop : '0px'}}
                                    type="number"
                                    value={valPack}
                                    onChange={(e) => setValPack(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between mt-3">
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <Button variant="secondary" onClick={handleCloseConstant}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateConstantForm;
